<ng-template vdrDialogTitle>
  Select products
</ng-template>

<floor-component-selector
  (selectedValuesChange)="selectedValues = $event"
  [multiple]="multiple"
/>

<ng-template vdrDialogButtons>
  <button
    type="button"
    class="btn"
    (click)="cancel()"
  >
    {{ 'common.cancel' | translate }}
  </button>
  <button
    type="submit"
    (click)="selectValues()"
    [disabled]="selectedValues.length === 0"
    class="btn btn-primary"
  >
    Add selected floor components
  </button>
</ng-template>
