import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import {
  CreateFloorBayOptionInput,
  FloorBayOption,
} from '../../../gql/graphql';

@Component({
  selector: 'floor-bay-option-dialog',
  templateUrl: './floor-bay-option-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorBayOptionDialogComponent implements Dialog<CreateFloorBayOptionInput>, AfterViewInit, OnInit {
  resolveWith: (result?: CreateFloorBayOptionInput) => void;

  @ViewChild('name')
  private _nameInput: any;

  @Input()
  bayId: string;

  @Input()
  option: FloorBayOption | null;

  form = this._formBuilder.group({
    name: ['', Validators.required],
  });

  constructor(
    private readonly _formBuilder: FormBuilder,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => this._nameInput?.nativeElement?.focus(), 100);
  }

  ngOnInit(): void {
    if (!this.bayId) {
      throw new Error('bayId is required');
    }
    if (this.option) {
      this.form.patchValue({
        name: this.option.name,
      });
    }
  }

  submit() {
    const { name } = this.form.value;
    if (!name) {
      return;
    }
    const result: CreateFloorBayOptionInput = {
      name,
      floorBayId: this.bayId,
      products: [],
    };
    this.resolveWith(result);
  }

  cancel() {
    this.resolveWith();
  }
}
