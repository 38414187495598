import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomDetailComponent, DataService, SharedModule } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { graphql } from '../../gql';
import {
  ConfiguratorProduct,
  ConfiguratorProductDocument,
  Floor,
  Rack,
} from '../../gql/graphql';
import { FloorAttributesComponent } from './floor-attributes/floor-attributes.component';

export const getConfiguratorProductDocument = graphql(`
  query ConfiguratorProduct($id: ID!) {
    configuratorProduct(id: $id) {
      configuratorProduct {
        __typename
        ...on Floor {
          __typename
          code
          productId
          productVariantId
          combinations {
            id
            features
            options {
              id
              name
            }
          }
          bays {
            id
            code
            options {
              id
              name
              products {
                id
                position
                productVariantId
                global {
                  code
                  productId
                  productVariantId
                }
              }
            }
          }
        }
        ...on Rack {
          __typename
          code
        }
        ...on PlyLining {
          __typename
          code
        }
        ...on Global {
          __typename
          code
        }
      }
    }
  }
`);

@Component({
  selector: 'product-variant-attributes',
  templateUrl: './product-variant-attributes.component.html',
  styleUrls: ['./product-variant-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    FloorAttributesComponent,
  ],
})
export class ProductVariantAttributesComponent implements CustomDetailComponent, OnInit {
  // These two properties are provided by Vendure and will vary
  // depending on the particular detail page you are embedding this
  // component into. In this case, it will be a "product" entity.
  entity$: Observable<any>;
  detailForm: FormGroup;
  configuratorProduct: ConfiguratorProduct | null;

  constructor(
    private readonly _dataService: DataService,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {
  }

  get isFloor() {
    return this.configuratorProduct?.__typename === 'Floor';
  }

  get floor() {
    if (!this.isFloor) {
      throw new Error('Not a floor');
    }
    return this.configuratorProduct as Floor;
  }

  get isRack() {
    return this.configuratorProduct?.__typename === 'Rack';
  }

  get rack() {
    if (!this.isRack) {
      throw new Error('Not a rack');
    }
    return this.configuratorProduct as Rack;
  }

  get isPlyLining() {
    return this.configuratorProduct?.__typename === 'PlyLining';
  }

  get isGlobal() {
    return this.configuratorProduct?.__typename === 'Global';
  }

  ngOnInit() {
    this.entity$
      .subscribe((entity) => {
        this._dataService
          .query(ConfiguratorProductDocument, { id: entity.id })
          .mapSingle(({ configuratorProduct }) => {
            return configuratorProduct?.configuratorProduct ?? null;
          })
          .subscribe((configuratorProduct) => {
            this.configuratorProduct = configuratorProduct as any as (ConfiguratorProduct | null);
            this._changeDetector.detectChanges();
          });
        return `Data for ${entity.id}`;
      });
  }
}