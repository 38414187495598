import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DataService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { graphql } from '../../../gql';
import {
  Floor,
  FloorCombination,
  FloorCombinationsDocument, UpdateFloorCombinationFeaturesDocument,
} from '../../../gql/graphql';
import { CommonModule } from '../../common/common.module';

type CombBay = {
  bayCode: string;
  options: string[];
  products: { code: string; position: string }[];
};

type Comb = {
  combination: FloorCombination;
  bays: CombBay[];
}

export const getFloorCombinations = graphql(`
  query FloorCombinations($productVariantId: ID!) {
    floor(productVariantId: $productVariantId) {
      floor {
        productVariantId
        floorLength
        type
        glb {
          source
        }
        materialGroup {
          id
          name
          materials {
            code
          }
        }
        bays {
          code
          options {
            id
            name
            products {
              id
              productVariantId
              position
              global {
                code
              }
            }
          }
        }
        combinations {
          id
          features
          options {
            id
            name
            products {
              id
              position
              productVariantId
              global {
                code
              }
            }
          }
        }
      }
    }
  }
`);
export const updateFloorCombinationFeatures = graphql(`
  mutation UpdateFloorCombinationFeatures($input: UpdateFloorCombinationFeaturesInput!) {
    updateFloorCombinationFeatures(input: $input) {
      success
    }
  }
`);

@Component({
  selector: 'combinations',
  templateUrl: './combinations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
  ],
})
export class CombinationsComponent implements OnInit {
  @Input()
  productVariantId: string;
  private _combinations: Comb[] = [];
  private _floor: Floor | null = null;

  get combinations() {
    return this._combinations;
  }

  set combinations(combinations: Comb[]) {
    this._combinations = combinations;
    this._changeDetector.markForCheck();
  }

  constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _dataService: DataService,
    private readonly _notifications: NotificationService,
  ) {
  }

  removeFeature(combination: FloorCombination, feature: string) {
    const features: string[] = combination.features.filter((f) => f !== feature);
    this._updateFeatures(combination, features);
  }

  onAddFeature(combination: FloorCombination) {
    const feature = window.prompt('Enter the feature value:');
    if (feature) {
      this.addFeature(combination, feature);
    }
  }

  addFeature(combination: FloorCombination, feature: string) {
    if (combination.features.includes(feature)) {
      this._notifications.warning('Feature already exists');
      return;
    }
    const features: string[] = [...combination.features, feature];
    this._updateFeatures(combination, features);
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this._dataService.query(
      FloorCombinationsDocument,
      { productVariantId: this.productVariantId },
    )
      .mapSingle(({ floor }) => floor)
      .subscribe((floor) => {
        if (floor) {
          this._floor = floor.floor as Floor;
          const combinations = (floor.floor.combinations ?? []) as FloorCombination[];
          this.combinations = this._mapCombinations(combinations);
        } else {
          this._floor = null;
        }
      });
  }

  private _updateFeatures(combination: FloorCombination, features: string[]) {
    this._dataService.mutate(
      UpdateFloorCombinationFeaturesDocument,
      {
        input: {
          features,
          floorCombinationId: combination.id,
          productVariantId: this.productVariantId,
        },
      },
    )
      .subscribe(({ updateFloorCombinationFeatures }) => {
        if (updateFloorCombinationFeatures.success) {
          this.combinations = this._combinations.map((x) => {
            if (x.combination.id === combination.id) {
              return {
                ...x,
                combination: {
                  ...x.combination,
                  features,
                },
              };
            }
            return x;
          });
          this._notifications.success('Updated');
        } else {
          this._notifications.error('Failed to update');
        }
      });
  }

  private _mapCombinations(x: FloorCombination[]): Comb[] {
    return x.map(x => {
      return {
        combination: x,
        ...this._getCombinationName(x),
      };
    });
  }

  private _getCombinationName(
    x: FloorCombination,
  ): { bays: CombBay[] } {
    if (!this._floor?.bays?.length) {
      return { bays: [] };
    }
    const optionIds = x.options.map((o) => o.id);
    const bays = this._floor.bays.flatMap((b) => {
      const opts = b.options.filter((o) => optionIds.includes(o.id));
      return {
        bayCode: b.code.toUpperCase(),
        options: opts.map((o) => o.name),
        products: opts.flatMap((o) => o.products.map((p) => ({
          code: p.global.code,
          position: p.position,
        }))),
      };
    }).sort((a, b) => a.bayCode > b.bayCode ? 1 : -1);
    return { bays };
  }
}