import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  ViewChild,
} from '@angular/core';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import { MaterialGroup } from '../../../gql/graphql';
import { MaterialGroupSelectorComponent } from '../material-group-selector/material-group-selector.component';

@Component({
  selector: 'apply-group-dialog',
  templateUrl: './apply-group-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyGroupDialogComponent implements Dialog<MaterialGroup[]>, AfterViewInit {
  @ViewChild(MaterialGroupSelectorComponent)
  private selector: MaterialGroupSelectorComponent;
  resolveWith: (result?: MaterialGroup[]) => void;
  selectedValues: MaterialGroup[] = [];
  // Provided by caller
  groups: MaterialGroup[]; // todo?
  @Input()
  multiple: boolean = true;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.selector.focus(), 0);
  }

  selectValues() {
    this.resolveWith(this.selectedValues);
  }

  cancel() {
    this.resolveWith();
  }
}
