import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Input, OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import {
  ConfiguratorProduct,
  CreateFloorBayOptionInput,
  CreateFloorBayOptionProductInput, FloorBayCode, FloorBayOption, FloorBayOptionProduct,
} from '../../../gql/graphql';

@Component({
  selector: 'floor-bay-option-product-dialog',
  templateUrl: './floor-bay-option-product-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorBayOptionProductDialogComponent implements Dialog<CreateFloorBayOptionProductInput>, AfterViewInit, OnInit {
  // @ViewChild(BayCodeSelectorComponent)
  // private selector: BayCodeSelectorComponent;
  resolveWith: (result?: CreateFloorBayOptionProductInput) => void;
  @Input()
  option: FloorBayOption;
  @Input()
  product: FloorBayOptionProduct | null = null;

  form = this._formBuilder.group({
    position: ['', Validators.required],
    productVariantId: ['', Validators.required],
  });

  constructor(
    private readonly _formBuilder: FormBuilder,
  ) {}

  onFloorComponentsSelected(products: ConfiguratorProduct) {
    this.form.patchValue({
      productVariantId: products?.productVariantId,
    });
  }

  ngOnInit(): void {
    if (this.product) {
      this.form.patchValue({
        position: this.product.position,
        productVariantId: this.product.productVariantId,
      });
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => this.selector.focus(), 0);
  }

  submit() {
    const { productVariantId, position } = this.form.value;
    if (!productVariantId || !position) {
      return;
    }
    const result: CreateFloorBayOptionProductInput = {
      floorBayOptionId: this.option.id,
      position,
      productVariantId,
    };
    this.resolveWith(result);
  }

  cancel() {
    this.resolveWith();
  }
}
