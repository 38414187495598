<ng-select
  [items]="searchResults$ | async"
  [addTag]="false"
  [hideSelected]="true"
  [loading]="searchLoading"
  [typeahead]="searchInput$"
  [multiple]="multiple"
  appendTo="body"
  bindLabel="id"
  [disabled]="disabled || readonly"
  [ngModel]="value"
  (change)="onChange($event)"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <vdr-chip
      *ngIf="item; else notFound"
      [icon]="times"
      colorType="success"
      (iconClick)="clear(item)"
      class="mr-1"
    >
      <span>[{{ item.id }}] {{ item.name }}</span>
    </vdr-chip>
    <ng-template #notFound>
      <vdr-chip colorType="error" icon="times" (iconClick)="clear(item)">
        No van base found
      </vdr-chip>
    </ng-template>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    [{{ item.id }}] {{ item.name }}
  </ng-template>
</ng-select>
