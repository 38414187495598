import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '@vendure/admin-ui/core';
import { concat, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { Material, MaterialsDocument } from '../../../gql/graphql';

@Component({
  selector: 'material-selector',
  templateUrl: './material-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MaterialSelectorComponent,
      multi: true,
    },
  ],
})
export class MaterialSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() selectedValuesChange = new EventEmitter<Material[]>();
  @Input() readonly = false;
  @Input() transformControlValueAccessorValue: (value: Material[]) => any[] = value => value;
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<Material[]>;
  selectedIds$ = new Subject<string[]>();

  @ViewChild(NgSelectComponent) private ngSelect: NgSelectComponent;

  onChangeFn: (val: any) => void;
  onTouchFn: () => void;
  disabled = false;
  value: Array<string | Material>;
  private subscription: Subscription;

  constructor(
    private readonly _dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initSearchResults();
  }

  private initSearchResults() {
    const searchItems$ = this.searchInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searchLoading = true)),
      switchMap(term => {
        if (!term) {
          return of([]);
        }
        return this._dataService.query(MaterialsDocument, {
          options: {
            filter: { name: { contains: term } },
          },
        })
          .mapSingle(result => result.materials.items as any as Material[]);
      }),
      tap(() => (this.searchLoading = false)),
    );
    this.subscription = this.selectedIds$
      .pipe(
        switchMap(ids => {
          if (!ids.length) {
            return of([]);
          }
          return this._dataService.query(MaterialsDocument, {
            options: {
              filter: { id: { in: ids } },
            },
          })
            .mapSingle(result => result.materials.items as any as Material[]);
        }),
      )
      .subscribe(val => {
        this.value = val;
        this.changeDetectorRef.markForCheck();
      });

    const clear$ = this.selectedValuesChange.pipe(mapTo([]));
    this.searchResults$ = concat(of([]), merge(searchItems$, clear$));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onChange(selected: Material[]) {
    if (this.readonly) {
      return;
    }
    for (const sel of selected) {
      console.log(`selected: ${sel.name}:${sel.id}`);
    }
    this.selectedValuesChange.emit(selected);
    if (this.onChangeFn) {
      const transformedValue = this.transformControlValueAccessorValue(selected);
      this.onChangeFn(transformedValue);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focus() {
    this.ngSelect.focus();
  }

  writeValue(obj: string | Material[] | Array<string | number> | null): void {
    let valueIds: string[] | undefined;
    if (typeof obj === 'string') {
      try {
        const materialIds = JSON.parse(obj) as string[];
        valueIds = materialIds;
      } catch (err) {
        // TODO: log error
        throw err;
      }
    } else if (Array.isArray(obj)) {
      const isIdArray = (input: unknown[]): input is Array<string | number> =>
        input.every(i => typeof i === 'number' || typeof i === 'string');
      if (isIdArray(obj)) {
        valueIds = obj.map(fv => fv.toString());
      } else {
        valueIds = obj.map(fv => fv.id);
      }
    }
    if (valueIds) {
      // this.value = valueIds;
      this.selectedIds$.next(valueIds);
    }
  }
}
