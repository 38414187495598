import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TypedBaseListComponent, SharedModule, NotificationService, DataService } from '@vendure/admin-ui/core';
import { graphql } from '../../gql';
import { DeleteMaterialDocument } from '../../gql/graphql';
import { lastValueFrom } from 'rxjs';

const getMaterialListDocument = graphql(`
  query Materials($options: MaterialListOptions) {
    materials(options: $options) {
      items {
        id
        createdAt
        updatedAt
        name
        code
        asset {
          id
          source
          preview
          name
        }
        featuredAsset {
          id
          source
          preview
          name
        }
      }
      totalItems
    }
  }
`);

const deleteMaterialDocument = graphql(`
  mutation DeleteMaterial($id: [ID!]!) {
    deleteMaterial(id: $id) {
      result
    }
  }
`);

@Component({
  selector: 'material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class MaterialListComponent extends TypedBaseListComponent<typeof getMaterialListDocument, 'materials'> {
  readonly filters = this.createFilterCollection()
    .addIdFilter()
    .addFilter({
      name: 'code',
      type: { kind: 'text' },
      label: 'Code',
      filterField: 'code',
    })
    .connectToRoute(this.route);

  readonly sorts = this.createSortCollection()
    .defaultSort('createdAt', 'DESC')
    .addSort({ name: 'createdAt' })
    .addSort({ name: 'updatedAt' })
    .addSort({ name: 'name' })
    .connectToRoute(this.route);

  readonly location: string;

  constructor(
    private readonly _dataService: DataService,
    private readonly _notifications: NotificationService,
  ) {
    super();
    this.location = window.location.href;
    super.configure({
      document: getMaterialListDocument,
      getItems: (data) => data.materials,
      setVariables: (skip, take) => ({
        options: {
          skip,
          take,
          filter: {
            code: {
              contains: this.searchTermControl.value,
            },
            ...this.filters.createFilterInput(),
          },
          sort: this.sorts.createSortInput(),
        },
      }),
      refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
    });
  }

  async deleteMany(id: string[]) {
    await lastValueFrom(
      this._dataService.mutate(DeleteMaterialDocument, { id }),
    );
    this.refresh();
  }
}