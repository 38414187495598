<div *ngFor="let bay of bays">
  <div class="bay">
    <div class="bay-header">
      <vdr-chip [colorFrom]="bay.code">
        Bay {{ bay.code.toUpperCase() }}
      </vdr-chip>
      <div class="flex-spacer"></div>
      <button
        title="Remove the bay"
        class="icon-button"
        (click)="removeBay(bay)"
      >
        <clr-icon
          shape="trash"
          class="is-danger"
        />
      </button>
    </div>
    <div class="options">

      <table class="tbl tbl-rounded">
        <thead>
        <tr>
          <th style="width: 30%;">Option name</th>
          <th>Products</th>
          <th style="width: 50px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let option of bay.options">
          <td>
            {{ option.name }}
          </td>
          <td>
            <table class="tbl">
              <thead>
              <tr>
                <th class="color-gray" style="width: 30px;">
                  Id
                </th>
                <th>
                  Code
                </th>
                <th style="width: 100px;">
                  Position
                </th>
                <th style="width: 50px;"></th>
              </tr>
              </thead>
              <tbody>
              <tr
                *ngFor="let product of option.products"
              >
                <td class="color-gray">
                  {{ product.id }}
                </td>
                <td>
                  {{ product.global.code }}
                </td>
                <td>
                  {{ product.position }}
                </td>
                <td>
                  <button
                    title="Remove the option product"
                    class="icon-button tbl-show-on-hover"
                    (click)="removeOptionProduct(product)"
                  >
                    <clr-icon shape="trash"/>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <div class="bay-option-actions tbl-show-on-hover">
                    <button
                      title="Add a product"
                      class="button-small"
                      (click)="onAddProduct(option)"
                    >
                      <clr-icon shape="plus"/>
                      Add a product
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
          <td>
            <button
              title="Remove the option"
              class="icon-button tbl-show-on-hover"
              (click)="removeOption(option)"
            >
              <clr-icon shape="trash"/>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="flex">
              <button
                title="Add an option"
                class="button-small"
                (click)="onAddOption(bay)"
              >
                <clr-icon shape="plus"/>
                Add an option
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<button
  class="btn"
  (click)="onAddBay()"
>
  <clr-icon shape="plus"/>
  Add a bay
</button>
