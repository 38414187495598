<vdr-page-block>
  <table class="table mt-2">
    <thead>
    <th class="left">
      ID
    </th>
    <th class="left">
      Should have facet values
    </th>
    <th class="left">
      Material group
    </th>
    <th></th>
    </thead>
    <tbody>
    <tr
      class="left"
      *ngFor="let item of items"
    >
      <td class="left text-gray">
        {{ item.id }}
      </td>
      <td class="left">
        <div
          style="display: flex; align-items: center; gap: 0.5rem;"
          [style.paddingLeft.px]="item.level * 28"
        >
          <clr-icon
            *ngIf="item.level > 0; else root"
            shape="child-arrow mr-2"
          />
          <ng-template #root>
            <clr-icon shape="folder mr-2"/>
          </ng-template>
          <vdr-chip
            *ngFor="let value of item.facetValues"
            icon="times"
            [colorFrom]="value.facet.name"
            (iconClick)="removeFacetValueFromAssigment(item, value)"
            [title]="'[' + value.id + '] ' + value.code"
          >
            <a
              class="inline-link"
              [routerLink]="['/catalog/facets', value.facet?.id]"
            >
              <span>{{ value.facet?.name }} - {{ value.name }}</span>
            </a>
          </vdr-chip>
          <button
            (click)="addFacetValueAndSave(item)"
            class="icon-button"
            title="Add a facet value"
          >
            <clr-icon shape="plus"/>
          </button>
        </div>
      </td>
      <td class="left">
        <div style="display: flex; align-items: center; gap: 0.5rem;">

          <button
            *ngIf="!item.materialGroup"
            (click)="selectGroupAndSave(item)"
            class="button-ghost"
          >
            Select a group
          </button>

          <vdr-chip
            *ngIf="item.materialGroup"
            [colorFrom]="item.materialGroup.name"
            icon="close"
            (iconClick)="removeGroupAndSave(item)"
          >
            <a
              class="inline-link"
              [routerLink]="['/extensions/material/groups', item.materialGroup.id]"
            >
              <span>{{ item.materialGroup.name }}</span>
            </a>
          </vdr-chip>

          <button
            *ngIf="item.materialGroup"
            (click)="selectGroupAndSave(item)"
            class="icon-button"
            title="Change the material group"
          >
            <clr-icon shape="edit"/>
          </button>
        </div>
      </td>
      <td>
        <button class="icon-button mr-1">
          <clr-icon
            shape="trash"
            (click)="remove(item)"
          />
        </button>
        <button class="icon-button mr-1">
          <clr-icon
            shape="plus"
            (click)="add(item.id)"
          />
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="mt-2">
    <button
      class="button"
      (click)="add()"
    >
      Add an assigment
    </button>
  </div>
</vdr-page-block>