<ng-template vdrDialogTitle>
  {{ option ? 'Edit the option':'Create an option' }}
</ng-template>


<form
  class="form"
  [formGroup]="form"
>
  <vdr-form-field
    label="Name"
    for="name"
  >
    <input
      #name
      id="name"
      type="text"
      formControlName="name"
    />
  </vdr-form-field>
</form>

<ng-template vdrDialogButtons>
  <button
    type="button"
    class="btn"
    (click)="cancel()"
  >
    {{ 'common.cancel' | translate }}
  </button>
  <button
    type="submit"
    (click)="submit()"
    [disabled]="form.pristine || form.invalid"
    class="btn btn-primary"
  >
    {{ option ? 'Update':'Create' }}
  </button>
</ng-template>
