<ng-template vdrDialogTitle>Select material groups</ng-template>

<material-group-selector
  (selectedValuesChange)="selectedValues = $event"
  [multiple]="false"
/>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button
    type="submit"
    (click)="selectValues()"
    [disabled]="selectedValues.length === 0"
    class="btn btn-primary"
  >
    Add selected groups
  </button>
</ng-template>
