import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RelationCustomFieldConfig } from '@vendure/common/lib/generated-types';
import {
  FormInputComponent,
  DataService,
  SharedModule,
  ModalService,
} from '@vendure/admin-ui/core';
import { Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { VanBase } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import {
  VanBaseDocument,
} from '../../../gql/graphql';
import { ApplyVanBaseDialogComponent } from '../apply-van-base-dialog/apply-van-base-dialog.component';
import { notNullOrUndefined } from '@vendure/common/lib/shared-utils';

export const getVanBaseDocument = graphql(`
  query VanBase($id: ID!) {
    vanBase(id: $id) {
      id
      vanCode
      vanType
      bodyLength
      vans {
        id
        code
        name
        types
        lengths
        options {
          id
          name
        }
      }
    }
  }
`);

@Component({
  selector: 'van-base-input',
  template: `
    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
      <div class="flex">
        <vdr-chip
          *ngIf="formControl.value"
          icon="times"
          colorType="success"
          (iconClick)="clean()"
          class="mr-1"
        >
          <span>[{{ formControl.value.id }}] {{ formControl.value.name }}</span>
        </vdr-chip>

        <button
          class="button-ghost"
          (click)="select()"
        >
          Select a van base
          <clr-icon shape="edit"/>
        </button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class VanBaseInputComponent implements OnInit, FormInputComponent<RelationCustomFieldConfig> {
  readonly: boolean;
  formControl: FormControl;
  config: RelationCustomFieldConfig;
  vanBase$: Observable<VanBase | null>;

  constructor(
    private readonly _dataService: DataService,
    private readonly _route: ActivatedRoute,
    private readonly _modalService: ModalService,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {}

  async clean() {
    this.formControl.setValue(null);
    this.formControl.markAsDirty();
    this._changeDetector.markForCheck();
  }

  async select() {
    this._displayModal().subscribe((groups) => {
      if (groups?.length) {
        this.formControl.setValue(groups[0]);
        this.formControl.markAsDirty();
        this._changeDetector.markForCheck();
      }
    });
  }

  ngOnInit() {
    this.vanBase$ = this._route.data.pipe(
      switchMap((data) => data.detail.entity),
      filter(notNullOrUndefined),
      switchMap((entity: any) => {
        if (!entity.id) {
          return of(null);
        }
        return this._dataService
          .query(VanBaseDocument, { id: entity.id })
          .mapSingle(({ vanBase }) => vanBase as (VanBase | null));
      }),
    );
  }

  private _displayModal(): Observable<VanBase[] | undefined> {
    return this._modalService.fromComponent(ApplyVanBaseDialogComponent, {
      size: 'md',
      closable: true,
      locals: {
        multiple: false,
      },
    });
  }
}