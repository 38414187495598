import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Input,
  ViewChild,
} from '@angular/core';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import { ConfiguratorProduct } from '../../../gql/graphql';
import { FloorComponentSelectorComponent } from '../floor-component-selector/floor-component-selector.component';

@Component({
  selector: 'apply-floor-component-dialog',
  templateUrl: './apply-floor-component-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyFloorComponentDialogComponent implements Dialog<ConfiguratorProduct[]>, AfterViewInit {
  @ViewChild(FloorComponentSelectorComponent)
  private selector: FloorComponentSelectorComponent;
  resolveWith: (result?: ConfiguratorProduct[]) => void;
  selectedValues: ConfiguratorProduct[] = [];
  @Input()
  multiple: boolean = true;


  ngAfterViewInit() {
    setTimeout(() => this.selector.focus(), 0);
  }

  selectValues() {
    this.resolveWith(this.selectedValues);
  }

  cancel() {
    this.resolveWith();
  }
}
