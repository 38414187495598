import {
  addNavMenuSection, registerFormInputComponent,
} from '@vendure/admin-ui/core';
import { VanBaseInputComponent } from './components/common/van-base-input/van-base-input.component';

export default [
  /**
   * Inputs
   */
  registerFormInputComponent('van-base-input', VanBaseInputComponent),

  /**
   * Nav
   */
  addNavMenuSection(
    {
      id: 'vans',
      label: 'Vans',
      items: [
        {
          id: 'van-list',
          label: 'Vans',
          routerLink: ['/extensions/van/vans'],
          // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
          icon: 'list',
        },
        {
          id: 'van-base-list',
          label: 'Van bases',
          routerLink: ['/extensions/van/van-bases'],
          // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
          icon: 'list',
        },
      ],
    },
    'sales',
  ),
];
