import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  ViewChild,
} from '@angular/core';
import {
  Dialog, SharedModule,
} from '@vendure/admin-ui/core';
import { VanBase } from '../../../gql/graphql';
import { CommonModule } from '../common.module';
import { VanBaseSelectorComponent } from '../van-base-selector/van-base-selector.component';

@Component({
  selector: 'apply-van-base-dialog',
  templateUrl: './apply-van-base-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, CommonModule],
  standalone: true,
})
export class ApplyVanBaseDialogComponent implements Dialog<VanBase[]>, AfterViewInit {
  @ViewChild(VanBaseSelectorComponent)
  private selector: VanBaseSelectorComponent;
  resolveWith: (result?: VanBase[]) => void;
  selectedValues: VanBase[] = [];
  @Input()
  multiple: boolean = true;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.selector.focus(), 0);
  }

  selectValues() {
    this.resolveWith(this.selectedValues);
  }

  cancel() {
    this.resolveWith();
  }
}
