<table class="tbl tbl-rounded tbl-striped mt-2">
  <thead>
  <tr>
    <th>
      Options
    </th>
    <th style="width: 100px;">
      Features
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let x of combinations">
    <td>
      <table class="tbl">
        <thead>
        <tr>
          <th style="width: 30px;">
            Bay
          </th>
          <th style="width: 150px;">
            Option
          </th>
          <th>
            Products
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bay of x.bays">
          <td>
            {{ bay.bayCode }}
          </td>
          <td>
            <div *ngFor="let option of bay.options">
              {{ option }}
            </div>
          </td>
          <td>
            <table
              class="tbl"
              *ngIf="bay.products.length; else noProducts"
            >
              <tbody>
              <tr *ngFor="let product of bay.products">
                <td style="width: 60px;">
                  {{ product.position }}
                </td>
                <td>
                  {{ product.code }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-template #noProducts>
              <div class="color-gray">
                Option has no products
              </div>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
    <td style="vertical-align: top;">
      <div *ngFor="let feature of x.combination.features">
        <vdr-chip
          icon="times"
          colorType="success"
          (iconClick)="removeFeature(x.combination, feature)"
          [title]="feature"
        >
          <div style="max-width: 100px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
            {{ feature }}
          </div>
        </vdr-chip>
      </div>
      <button
        class="button-ghost"
        (click)="onAddFeature(x.combination)"
      >
        <clr-icon shape="plus"/>
        Add a feature
      </button>
    </td>
  </tr>
  </tbody>
</table>
