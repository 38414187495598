/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query GetMaterialCore($id: ID!) {\n    materialCore(id: $id) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n": types.GetMaterialCoreDocument,
    "\n  mutation CreateMaterialCore($input: CreateMaterialCoreInput!) {\n    createMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n": types.CreateMaterialCoreDocument,
    "\n  mutation UpdateMaterialCore($input: UpdateMaterialCoreInput!) {\n    updateMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n": types.UpdateMaterialCoreDocument,
    "\n  fragment AssetDetail on Asset {\n    id\n    source\n    preview\n    name\n  }\n": types.AssetDetailFragmentDoc,
    "\n  fragment MaterialDetail on Material {\n    id\n    createdAt\n    updatedAt\n    name\n    code\n    asset { ...AssetDetail }\n    assets { ...AssetDetail }\n    featuredAsset { ...AssetDetail }\n    defaultCoreId\n    cores {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n    groups {\n      id\n      createdAt\n      updatedAt\n      name\n    }\n  }\n": types.MaterialDetailFragmentDoc,
    "\n  query GetMaterial($id: ID!) {\n    material(id: $id) {\n      ...MaterialDetail\n    }\n  }\n": types.GetMaterialDocument,
    "\n  mutation CreateMaterial($input: CreateMaterialInput!) {\n    createMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n": types.CreateMaterialDocument,
    "\n  mutation UpdateMaterial($input: UpdateMaterialInput!) {\n    updateMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n": types.UpdateMaterialDocument,
    "\n  mutation DeleteMaterialCore($id: [ID!]!) {\n    deleteMaterialCore(id: $id) {\n      result\n    }\n  }\n": types.DeleteMaterialCoreDocument,
    "\n  mutation AddMaterialToGroup($input: AddMaterialToGroupInput!) {\n    addMaterialToGroup(input: $input) {\n      success\n    }\n  }\n": types.AddMaterialToGroupDocument,
    "\n  query MaterialGroupAssigment {\n    materialGroupAssigment {\n      id\n      parentId\n      materialGroup {\n        id\n        name\n      }\n      facetValues {\n        id\n        code\n        name\n        facet {\n          id\n          name\n          code\n        }\n      }\n    }\n  }\n": types.MaterialGroupAssigmentDocument,
    "\n  mutation AddFacetValueToMaterialGroupAssigment($input: AddFacetValueToMaterialGroupAssigmentInput!) {\n    addFacetValueToMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n": types.AddFacetValueToMaterialGroupAssigmentDocument,
    "\n  mutation RemoveFacetValueToMaterialGroupAssigment($input: RemoveFacetValueFromMaterialGroupAssigmentInput!) {\n    removeFacetValueFromMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n": types.RemoveFacetValueToMaterialGroupAssigmentDocument,
    "\n  mutation CreateMaterialGroupAssigment($input: CreateMaterialGroupAssigmentInput!) {\n    createMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n": types.CreateMaterialGroupAssigmentDocument,
    "\n  mutation UpdateMaterialGroupAssigment($input: UpdateMaterialGroupAssigmentInput!) {\n    updateMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n": types.UpdateMaterialGroupAssigmentDocument,
    "\n  mutation DeleteMaterialGroupAssigment($id: [ID!]!) {\n    deleteMaterialGroupAssigment(id: $id) {\n      result\n    }\n  }\n": types.DeleteMaterialGroupAssigmentDocument,
    "\n  fragment MaterialGroupDetail on MaterialGroup {\n    id\n    createdAt\n    updatedAt\n    name\n    defaultMaterialId\n    materials { ...MaterialDetail }\n  }\n": types.MaterialGroupDetailFragmentDoc,
    "\n  query GetMaterialGroup($id: ID!) {\n    materialGroup(id: $id) {\n      ...MaterialGroupDetail\n    }\n  }\n": types.GetMaterialGroupDocument,
    "\n  mutation CreateMaterialGroup($input: CreateMaterialGroupInput!) {\n    createMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n": types.CreateMaterialGroupDocument,
    "\n  mutation UpdateMaterialGroup($input: UpdateMaterialGroupInput!) {\n    updateMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n": types.UpdateMaterialGroupDocument,
    "\n  mutation RemoveMaterialFromGroup($input: RemoveMaterialFromGroupInput!) {\n    removeMaterialFromGroup(input: $input) {\n      result\n    }\n  }\n": types.RemoveMaterialFromGroupDocument,
    "\n  query ProductMaterialGroup($productId: ID!) {\n    productMaterialGroup(productId: $productId) {\n      id\n      name\n      defaultMaterialId\n    }\n  }\n": types.ProductMaterialGroupDocument,
    "\n  query MaterialGroups($options: MaterialGroupListOptions) {\n    materialGroups(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        defaultMaterialId\n        materials {\n          id\n          name\n          code\n        }\n      }\n      totalItems\n    }\n  }\n": types.MaterialGroupsDocument,
    "\n  mutation DeleteMaterialGroup($id: [ID!]!) {\n    deleteMaterialGroup(id: $id) {\n      result\n    }\n  }\n": types.DeleteMaterialGroupDocument,
    "\n  query Materials($options: MaterialListOptions) {\n    materials(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        code\n        asset {\n          id\n          source\n          preview\n          name\n        }\n        featuredAsset {\n          id\n          source\n          preview\n          name\n        }\n      }\n      totalItems\n    }\n  }\n": types.MaterialsDocument,
    "\n  mutation DeleteMaterial($id: [ID!]!) {\n    deleteMaterial(id: $id) {\n      result\n    }\n  }\n": types.DeleteMaterialDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMaterialCore($id: ID!) {\n    materialCore(id: $id) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"): (typeof documents)["\n  query GetMaterialCore($id: ID!) {\n    materialCore(id: $id) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMaterialCore($input: CreateMaterialCoreInput!) {\n    createMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMaterialCore($input: CreateMaterialCoreInput!) {\n    createMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialCore($input: UpdateMaterialCoreInput!) {\n    updateMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialCore($input: UpdateMaterialCoreInput!) {\n    updateMaterialCore(input: $input) {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AssetDetail on Asset {\n    id\n    source\n    preview\n    name\n  }\n"): (typeof documents)["\n  fragment AssetDetail on Asset {\n    id\n    source\n    preview\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MaterialDetail on Material {\n    id\n    createdAt\n    updatedAt\n    name\n    code\n    asset { ...AssetDetail }\n    assets { ...AssetDetail }\n    featuredAsset { ...AssetDetail }\n    defaultCoreId\n    cores {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n    groups {\n      id\n      createdAt\n      updatedAt\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment MaterialDetail on Material {\n    id\n    createdAt\n    updatedAt\n    name\n    code\n    asset { ...AssetDetail }\n    assets { ...AssetDetail }\n    featuredAsset { ...AssetDetail }\n    defaultCoreId\n    cores {\n      id\n      materialId\n      createdAt\n      updatedAt\n      name\n      pricePercent\n      assets { ...AssetDetail }\n      featuredAsset { ...AssetDetail }\n    }\n    groups {\n      id\n      createdAt\n      updatedAt\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMaterial($id: ID!) {\n    material(id: $id) {\n      ...MaterialDetail\n    }\n  }\n"): (typeof documents)["\n  query GetMaterial($id: ID!) {\n    material(id: $id) {\n      ...MaterialDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMaterial($input: CreateMaterialInput!) {\n    createMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMaterial($input: CreateMaterialInput!) {\n    createMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterial($input: UpdateMaterialInput!) {\n    updateMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterial($input: UpdateMaterialInput!) {\n    updateMaterial(input: $input) {\n      ...MaterialDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterialCore($id: [ID!]!) {\n    deleteMaterialCore(id: $id) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterialCore($id: [ID!]!) {\n    deleteMaterialCore(id: $id) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddMaterialToGroup($input: AddMaterialToGroupInput!) {\n    addMaterialToGroup(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation AddMaterialToGroup($input: AddMaterialToGroupInput!) {\n    addMaterialToGroup(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MaterialGroupAssigment {\n    materialGroupAssigment {\n      id\n      parentId\n      materialGroup {\n        id\n        name\n      }\n      facetValues {\n        id\n        code\n        name\n        facet {\n          id\n          name\n          code\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MaterialGroupAssigment {\n    materialGroupAssigment {\n      id\n      parentId\n      materialGroup {\n        id\n        name\n      }\n      facetValues {\n        id\n        code\n        name\n        facet {\n          id\n          name\n          code\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddFacetValueToMaterialGroupAssigment($input: AddFacetValueToMaterialGroupAssigmentInput!) {\n    addFacetValueToMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation AddFacetValueToMaterialGroupAssigment($input: AddFacetValueToMaterialGroupAssigmentInput!) {\n    addFacetValueToMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveFacetValueToMaterialGroupAssigment($input: RemoveFacetValueFromMaterialGroupAssigmentInput!) {\n    removeFacetValueFromMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveFacetValueToMaterialGroupAssigment($input: RemoveFacetValueFromMaterialGroupAssigmentInput!) {\n    removeFacetValueFromMaterialGroupAssigment(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMaterialGroupAssigment($input: CreateMaterialGroupAssigmentInput!) {\n    createMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMaterialGroupAssigment($input: CreateMaterialGroupAssigmentInput!) {\n    createMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialGroupAssigment($input: UpdateMaterialGroupAssigmentInput!) {\n    updateMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialGroupAssigment($input: UpdateMaterialGroupAssigmentInput!) {\n    updateMaterialGroupAssigment(input: $input) {\n      id\n      parentId\n      materialGroupId\n      facetValues {\n        id\n        code\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterialGroupAssigment($id: [ID!]!) {\n    deleteMaterialGroupAssigment(id: $id) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterialGroupAssigment($id: [ID!]!) {\n    deleteMaterialGroupAssigment(id: $id) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MaterialGroupDetail on MaterialGroup {\n    id\n    createdAt\n    updatedAt\n    name\n    defaultMaterialId\n    materials { ...MaterialDetail }\n  }\n"): (typeof documents)["\n  fragment MaterialGroupDetail on MaterialGroup {\n    id\n    createdAt\n    updatedAt\n    name\n    defaultMaterialId\n    materials { ...MaterialDetail }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMaterialGroup($id: ID!) {\n    materialGroup(id: $id) {\n      ...MaterialGroupDetail\n    }\n  }\n"): (typeof documents)["\n  query GetMaterialGroup($id: ID!) {\n    materialGroup(id: $id) {\n      ...MaterialGroupDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMaterialGroup($input: CreateMaterialGroupInput!) {\n    createMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMaterialGroup($input: CreateMaterialGroupInput!) {\n    createMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialGroup($input: UpdateMaterialGroupInput!) {\n    updateMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialGroup($input: UpdateMaterialGroupInput!) {\n    updateMaterialGroup(input: $input) {\n      ...MaterialGroupDetail\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveMaterialFromGroup($input: RemoveMaterialFromGroupInput!) {\n    removeMaterialFromGroup(input: $input) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveMaterialFromGroup($input: RemoveMaterialFromGroupInput!) {\n    removeMaterialFromGroup(input: $input) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductMaterialGroup($productId: ID!) {\n    productMaterialGroup(productId: $productId) {\n      id\n      name\n      defaultMaterialId\n    }\n  }\n"): (typeof documents)["\n  query ProductMaterialGroup($productId: ID!) {\n    productMaterialGroup(productId: $productId) {\n      id\n      name\n      defaultMaterialId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MaterialGroups($options: MaterialGroupListOptions) {\n    materialGroups(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        defaultMaterialId\n        materials {\n          id\n          name\n          code\n        }\n      }\n      totalItems\n    }\n  }\n"): (typeof documents)["\n  query MaterialGroups($options: MaterialGroupListOptions) {\n    materialGroups(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        defaultMaterialId\n        materials {\n          id\n          name\n          code\n        }\n      }\n      totalItems\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterialGroup($id: [ID!]!) {\n    deleteMaterialGroup(id: $id) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterialGroup($id: [ID!]!) {\n    deleteMaterialGroup(id: $id) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Materials($options: MaterialListOptions) {\n    materials(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        code\n        asset {\n          id\n          source\n          preview\n          name\n        }\n        featuredAsset {\n          id\n          source\n          preview\n          name\n        }\n      }\n      totalItems\n    }\n  }\n"): (typeof documents)["\n  query Materials($options: MaterialListOptions) {\n    materials(options: $options) {\n      items {\n        id\n        createdAt\n        updatedAt\n        name\n        code\n        asset {\n          id\n          source\n          preview\n          name\n        }\n        featuredAsset {\n          id\n          source\n          preview\n          name\n        }\n      }\n      totalItems\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterial($id: [ID!]!) {\n    deleteMaterial(id: $id) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterial($id: [ID!]!) {\n    deleteMaterial(id: $id) {\n      result\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;