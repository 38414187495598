import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RelationCustomFieldConfig } from '@vendure/common/lib/generated-types';
import {
  FormInputComponent,
  DataService,
  SharedModule,
  ModalService,
} from '@vendure/admin-ui/core';
import { Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { graphql } from '../../gql';
import {
  MaterialGroup,
  ProductMaterialGroupDocument,
} from '../../gql/graphql';
import { notNullOrUndefined } from '@vendure/common/lib/shared-utils';
import { ApplyGroupDialogComponent } from '../common/apply-group-dialog/apply-group-dialog.component';

export const getProductMaterialGroupDocument = graphql(`
  query ProductMaterialGroup($productId: ID!) {
    productMaterialGroup(productId: $productId) {
      id
      name
      defaultMaterialId
    }
  }
`);

@Component({
  selector: 'material-group-input',
  template: `
    <div style="display: flex; flex-direction: column; gap: 0.5rem;">

      <ng-container *ngIf="materialGroup$ | async as materialGroup">
        <div>
          Inherited material group
        </div>
        <div>
          <vdr-chip [colorFrom]="materialGroup.name">
            <a
              class="inline-link"
              [routerLink]="['/extensions', 'material', 'groups', materialGroup.id]"
            >
              {{ materialGroup.name }}
            </a>
          </vdr-chip>
        </div>
      </ng-container>

      <div>
        Overwrite material group
      </div>

      <div class="flex">
        <vdr-chip
          *ngIf="formControl.value"
          icon="times"
          [colorFrom]="formControl.value.name"
          (iconClick)="clean()"
          class="mr-1"
        >
          <span>{{ formControl.value.name }}</span>
        </vdr-chip>

        <button
          class="button-ghost"
          (click)="selectGroupAndAdd()"
        >
          Select a group
          <clr-icon shape="edit"/>
        </button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class MaterialGroupInputComponent implements OnInit, FormInputComponent<RelationCustomFieldConfig> {
  readonly: boolean;
  formControl: FormControl;
  config: RelationCustomFieldConfig;
  materialGroup$: Observable<MaterialGroup | null>;

  constructor(
    private readonly _dataService: DataService,
    private readonly _route: ActivatedRoute,
    private readonly _modalService: ModalService,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {}

  async clean() {
    this.formControl.setValue(null);
    this.formControl.markAsDirty();
    this._changeDetector.markForCheck();
  }

  async selectGroupAndAdd() {
    this._displayGroupModal().subscribe((groups) => {
      if (groups?.length) {
        this.formControl.setValue(groups[0]);
        this.formControl.markAsDirty();
        this._changeDetector.markForCheck();
      }
    });
  }

  ngOnInit() {
    this.materialGroup$ = this._route.data.pipe(
      switchMap((data) => data.detail.entity),
      filter(notNullOrUndefined),
      switchMap((entity: any) => {
        if (!entity.id) {
          return of(null);
        }
        return this._dataService
          .query(ProductMaterialGroupDocument, { productId: entity.id })
          .mapSingle(({ productMaterialGroup }) => productMaterialGroup as (MaterialGroup | null));
      }),
    );
  }

  private _displayGroupModal(): Observable<MaterialGroup[] | undefined> {
    return this._modalService.fromComponent(ApplyGroupDialogComponent, {
      size: 'md',
      closable: true,
      locals: {
        multiple: false,
      },
    });
  }
}