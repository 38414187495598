import {
  addNavMenuItem,
  registerDataTableComponent,
  registerCustomDetailComponent,
} from '@vendure/admin-ui/core';
import {
  OrderProductVariantSkuComponent,
} from './components/order-product-variant-sku/order-product-variant-sku.component';
import { ProductVariantAttributesComponent } from './components/product-variant-attributes/product-variant-attributes.component';

export default [
  addNavMenuItem(
    {
      id: 'config',
      label: 'Configs',
      routerLink: ['/extensions/configs'],
      // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
      icon: 'form',
    },
    'catalog',
  ),
  registerDataTableComponent({
    component: OrderProductVariantSkuComponent,
    tableId: 'order-detail-list',
    columnId: 'product-sku',
  }),

  registerCustomDetailComponent({
    locationId: 'product-variant-detail',
    component: ProductVariantAttributesComponent,
  }),
];
