import { SharedModule } from '@vendure/admin-ui/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ApplyBayCodeDialogComponent } from './apply-bay-code-dialog/apply-bay-code-dialog.component';
import { ApplyFloorComponentDialogComponent } from './apply-floor-component-dialog/apply-floor-component-dialog.component';
import { BayCodeSelectorComponent } from './bay-code-selector/bay-code-selector.component';
import { FloorBayDialogComponent } from './floor-bay-dialog/floor-bay-dialog.component';
import { FloorBayOptionDialogComponent } from './floor-bay-option-dialog/floor-bay-option-dialog.component';
import { FloorComponentSelectorComponent } from './floor-component-selector/floor-component-selector.component';
import { FloorBayOptionProductDialogComponent } from './floor-bay-option-product-dialog/floor-bay-option-product-dialog.component';

const DECLARATIONS = [
  BayCodeSelectorComponent,
  ApplyBayCodeDialogComponent,
  ApplyFloorComponentDialogComponent,
  FloorComponentSelectorComponent,
  FloorBayOptionProductDialogComponent,
  FloorBayOptionDialogComponent,
  FloorBayDialogComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModule {}