import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import {
  CreateFloorBayInput, FloorBay, FloorBayCode,
} from '../../../gql/graphql';

@Component({
  selector: 'floor-bay-dialog',
  templateUrl: './floor-bay-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorBayDialogComponent implements Dialog<CreateFloorBayInput>, AfterViewInit, OnInit {
  resolveWith: (result?: CreateFloorBayInput) => void;

  @ViewChild('code')
  private _codeInput: any;

  @Input()
  productVariantId: string;

  @Input()
  bay?: FloorBay;

  form = this._formBuilder.group({
    code: [FloorBayCode.a, Validators.required],
  });

  constructor(
    private readonly _formBuilder: FormBuilder,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => this._codeInput?.nativeElement?.focus(), 100);
  }

  get bayCodes() {
    return Object.values(FloorBayCode);
  }

  ngOnInit(): void {
    if (!this.productVariantId) {
      throw new Error('productVariantId is required');
    }
    this.form.patchValue({
      code: this.bay?.code ?? FloorBayCode.a,
    });
    this.form.markAsDirty();
  }

  submit() {
    const { code } = this.form.value;
    if (!code) {
      return;
    }
    const result: CreateFloorBayInput = {
      code,
      productVariantId: this.productVariantId,
    };
    this.resolveWith(result);
  }

  cancel() {
    this.resolveWith();
  }
}
