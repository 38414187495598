import {
  registerFormInputComponent,
  addNavMenuSection,
  ModalService,
  registerBulkAction,
  registerPageTab,
} from '@vendure/admin-ui/core';
import { MaterialGroupAssigmentComponent } from './components/material-group-assigment/material-group-assigment.component';
import { MaterialGroupInputComponent } from './components/material-group-input/material-group-input.component';
import { MaterialGroupListComponent } from './components/material-group-list/material-group-list.component';
import { MaterialListComponent } from './components/material-list/material-list.component';

export default [
  /**
   * Tabs
   */
  registerPageTab({
    priority: 0,
    location: 'material-group-list' as any,
    tab: 'Material groups',
    route: '/extensions/material/groups',
    component: MaterialListComponent,
  }),
  registerPageTab({
    priority: 0,
    location: 'material-group-list' as any,
    tab: 'Group assigment',
    route: '/extensions/material/group-assigment',
    component: MaterialGroupAssigmentComponent,
  }),
  registerPageTab({
    priority: 0,
    location: 'material-group-assigment' as any,
    tab: 'Material groups',
    route: '/extensions/material/groups',
    component: MaterialListComponent,
  }),
  registerPageTab({
    priority: 0,
    location: 'material-group-assigment' as any,
    tab: 'Group assigment',
    route: '/extensions/material/group-assigment',
    component: MaterialGroupAssigmentComponent,
  }),

  /**
   * Inputs
   */
  registerFormInputComponent('material-group-input', MaterialGroupInputComponent),

  /**
   * Nav menu
   */
  addNavMenuSection(
    {
      id: 'materials',
      label: 'Materials',
      items: [
        {
          id: 'material-list',
          label: 'Materials',
          routerLink: ['/extensions/material/materials'],
          // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
          icon: 'color-palette',
        },
        {
          id: 'material-group-list',
          label: 'Material groups',
          routerLink: ['/extensions/material/groups'],
          // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
          icon: 'list',
        },
      ],
    },
    'sales',
  ),

  /**
   * Bulk actions
   */
  registerBulkAction({
    location: 'material-list',
    label: 'Delete',
    // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
    icon: 'trash',
    onClick: ({ injector, hostComponent, selection }) => {
      const modalService = injector.get(ModalService);
      modalService
        .dialog({
          title: `Delete ${selection.length} material(s)?`,
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'primary', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(response => {
          if (response) {
            void (hostComponent as MaterialListComponent).deleteMany(selection.map((x) => x.id));
          }
        });
    },
  }),

  registerBulkAction({
    location: 'material-group-list',
    label: 'Delete',
    // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
    icon: 'trash',
    onClick: ({ injector, hostComponent, selection }) => {
      const modalService = injector.get(ModalService);
      modalService
        .dialog({
          title: `Delete ${selection.length} material group(s)?`,
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'primary', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(response => {
          if (response) {
            void (hostComponent as MaterialGroupListComponent).deleteMany(selection.map((x) => x.id));
          }
        });
    },
  }),
];
