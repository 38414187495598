<ng-select
  [items]="searchResults$ | async"
  [addTag]="false"
  [hideSelected]="true"
  [loading]="searchLoading"
  [typeahead]="searchInput$"
  [multiple]="multiple"
  appendTo="body"
  bindLabel="id"
  [disabled]="disabled || readonly"
  [ngModel]="value"
  (change)="onChange($event)"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <vdr-chip
      *ngIf="item; else facetNotFound"
      [icon]="times"
      [colorFrom]="item.name"
      (iconClick)="clear(item)"
      class="mr-1"
    >
      <span>{{ item.name }}</span>
    </vdr-chip>
    <ng-template #facetNotFound>
      <vdr-chip colorType="error" icon="times" (iconClick)="clear(item)">
        No group available
      </vdr-chip>
    </ng-template>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <vdr-chip
      [colorFrom]="item.name"
    >
      <span>{{ item.name }}</span>
    </vdr-chip>
  </ng-template>
</ng-select>
