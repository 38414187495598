<vdr-card
  title="Floor"
  class="mb-2"
>
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>
        Bays
      </button>
      <clr-tab-content *clrIfActive>
        <bays [floor]="floor"/>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>
        Combinations
      </button>
      <clr-tab-content *clrIfActive>
        <combinations [productVariantId]="floor.productVariantId"/>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</vdr-card>