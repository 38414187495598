import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '@vendure/admin-ui/core';
import { concat, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { FloorBayCode } from '../../../gql/graphql';

@Component({
  selector: 'bay-code-selector',
  templateUrl: './bay-code-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BayCodeSelectorComponent,
      multi: true,
    },
  ],
})
export class BayCodeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() selectedValuesChange = new EventEmitter<FloorBayCode[]>();
  @Input() readonly = false;
  @Input() transformControlValueAccessorValue: (value: FloorBayCode[]) => any[] = value => value;
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<FloorBayCode[]>;
  selectedIds$ = new Subject<string[]>();
  @Input() multiple = true;

  @ViewChild(NgSelectComponent) private ngSelect: NgSelectComponent;

  onChangeFn: (val: any) => void;
  onTouchFn: () => void;
  disabled = false;
  value: Array<string | FloorBayCode>;
  private subscription: Subscription;

  constructor(
    private readonly _dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initSearchResults();
  }

  private initSearchResults() {
    const searchItems$ = this.searchInput$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => (this.searchLoading = true)),
      switchMap(term => {
        return of(Object.values(FloorBayCode).filter((x) => !term || x.includes(term)));
      }),
      tap(() => (this.searchLoading = false)),
    );
    const clear$ = this.selectedValuesChange.pipe(mapTo([]));
    this.searchResults$ = concat(of([]), merge(searchItems$, clear$));
  }

  ngOnDestroy() {
  }

  onChange(selected: FloorBayCode[] | FloorBayCode) {
    if (this.readonly) {
      return;
    }
    const selection = Array.isArray(selected) ? selected : [selected];
    this.selectedValuesChange.emit(selection);
    if (this.onChangeFn) {
      const transformedValue = this.transformControlValueAccessorValue(selection);
      this.onChangeFn(transformedValue);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focus() {
    this.ngSelect.focus();
  }

  writeValue(obj: string | FloorBayCode[] | Array<string | number> | null): void {
    let valueIds: string[] | undefined;
    if (typeof obj === 'string') {
      try {
        valueIds = JSON.parse(obj) as string[];
      } catch (err) {
        // TODO: log error
        throw err;
      }
    } else if (Array.isArray(obj)) {
      const isIdArray = (input: unknown[]): input is Array<string | number> =>
        input.every(i => typeof i === 'number' || typeof i === 'string');
      if (isIdArray(obj)) {
        valueIds = obj.map(fv => fv.toString());
      }
    }
    if (valueIds) {
      this.selectedIds$.next(valueIds);
    }
  }
}
