<ng-template vdrDialogTitle>
  {{ product ? 'Edit the floor bay option product' : 'Add a floor bay option product' }}
</ng-template>


<form
  class="form"
  [formGroup]="form"
>
  <div class="form-grid">
    <vdr-form-field
      label="Position"
      for="position"
    >
      <input
        id="position"
        type="text"
        formControlName="position"
      />
    </vdr-form-field>


    <vdr-form-field
      label="Product"
      for="productVariantId"
    >
      <floor-component-selector
        id="productVariantId"
        (selectedValuesChange)="onFloorComponentsSelected($event)"
        [multiple]="false"
        style="width: 100%;"
      />
    </vdr-form-field>
  </div>
</form>

<ng-template vdrDialogButtons>
  <button
    type="button"
    class="btn"
    (click)="cancel()"
  >
    {{ 'common.cancel' | translate }}
  </button>
  <button
    type="submit"
    (click)="submit()"
    [disabled]="form.pristine || form.invalid"
    class="btn btn-primary"
  >
    {{ product ? 'Update' : 'Create' }}
  </button>
</ng-template>
