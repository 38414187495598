import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, ModalService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { lastValueFrom } from 'rxjs';
import { graphql } from '../../../gql';
import {
  AddProductsToFloorBayOptionDocument,
  CreateFloorBayDocument,
  CreateFloorBayOptionDocument,
  Floor,
  FloorBay,
  FloorBayCode, FloorBayOption, FloorBayOptionProduct,
  RemoveFloorBayDocument, RemoveFloorBayOptionsDocument, RemoveProductsFromFloorBayOptionDocument,
} from '../../../gql/graphql';
import { ApplyFloorComponentDialogComponent } from '../../common/apply-floor-component-dialog/apply-floor-component-dialog.component';
import { CommonModule } from '../../common/common.module';
import { FloorBayDialogComponent } from '../../common/floor-bay-dialog/floor-bay-dialog.component';
import { FloorBayOptionDialogComponent } from '../../common/floor-bay-option-dialog/floor-bay-option-dialog.component';
import { FloorBayOptionProductDialogComponent } from '../../common/floor-bay-option-product-dialog/floor-bay-option-product-dialog.component';

export const createFloorBayDocument = graphql(`
  mutation CreateFloorBay($input: CreateFloorBayInput!) {
    createFloorBay(input: $input) {
      id
      code
      options {
        id
        name
        products {
          position
          productVariantId
          global {
            code
          }
        }
      }
    }
  }
`);

export const removeFloorBayDocument = graphql(`
  mutation RemoveFloorBay($floorBayIds: [ID!]!) {
    removeFloorBay(floorBayIds: $floorBayIds) {
      message
      result
    }
  }
`);

export const removeFloorBayOptionsDocument = graphql(`
  mutation RemoveFloorBayOptions($floorBayOptionIds: [ID!]!) {
    removeFloorBayOptions(floorBayOptionIds: $floorBayOptionIds) {
      message
      result
    }
  }
`);

export const removeProductsFromFloorBayOptionDocument = graphql(`
  mutation RemoveProductsFromFloorBayOption($input: RemoveProductsFromFloorBayOptionInput!) {
    removeProductsFromFloorBayOption(input: $input) {
      message
      result
    }
  }
`);

export const createFloorBayOptionDocument = graphql(`
  mutation CreateFloorBayOption($input: CreateFloorBayOptionInput!) {
    createFloorBayOption(input: $input) {
      id
      name
      products {
        position
        global {
          code
          productVariantId
          productId
        }
      }
    }
  }
`);

export const createFloorBayOptionProductDocument = graphql(`
  mutation AddProductsToFloorBayOption($input: CreateFloorBayOptionProductsInput!) {
    addProductsToFloorBayOption(input: $input) {
      id
      name
      products {
        id
        position
        productVariantId
        global {
          code
        }
      }
    }
  }
`);

@Component({
  selector: 'bays',
  templateUrl: './bays.component.html',
  styleUrls: ['./bays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
  ],
})
export class BaysComponent implements OnInit {
  @Input()
  floor: Floor;

  private _bays: FloorBay[] = [];

  get bays() {
    return this._bays;
  }

  set bays(bays: FloorBay[]) {
    this._bays = bays.sort((a, b) => a.code.localeCompare(b.code));
    this._changeDetector.markForCheck();
  }

  constructor(
    private readonly _dataService: DataService,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _modalService: ModalService,
    private readonly _notifications: NotificationService,
  ) {
  }

  onAddBay() {
    this._modalService.fromComponent(FloorBayDialogComponent, {
      size: 'md',
      closable: true,
      locals: {
        productVariantId: this.floor.productVariantId,
      },
    })
      .subscribe((input) => {
        if (!input) {
          return;
        }
        void lastValueFrom(
          this._dataService.mutate(CreateFloorBayDocument, { input }),
        ).then(({ createFloorBay }) => {
          this._notifications.success(`Floor bay ${createFloorBay.code.toUpperCase()} added`);
          this.bays = [...this.bays, createFloorBay as FloorBay];
        });
      });
  }

  async removeOption(option: FloorBayOption) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove from the floor bay option ${option.id}?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          void lastValueFrom(
            this._dataService.mutate(RemoveFloorBayOptionsDocument, {
              floorBayOptionIds: [option.id],
            }),
          ).then(() => {
            this._notifications.success(`Floor bay option ${option.id} removed`);
            this.bays = this.bays.map((x) => ({
              ...x,
              options: x.options.filter((o) => o.id != option.id),
            }));
          });
        }
      });
  }

  async removeOptionProduct(product: FloorBayOptionProduct) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove from the floor bay option product ${product.id}?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          void lastValueFrom(
            this._dataService.mutate(RemoveProductsFromFloorBayOptionDocument, {
              input: {
                floorBayOptionProductIds: [product.id],
                productVariantId: this.floor.productVariantId,
              },
            }),
          ).then(() => {
            this._notifications.success(`Floor bay option product ${product.id} removed`);
            this.bays = this.bays.map((x) => ({
              ...x,
              options: x.options.map((o) => ({
                ...o,
                products: o.products.filter((p) => p.id !== product.id),
              })),
            }));
          });
        }
      });
  }

  removeBay(bay: FloorBay) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove from the floor bay ${bay.code.toUpperCase()}?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          void lastValueFrom(
            this._dataService.mutate(RemoveFloorBayDocument, {
              floorBayIds: [bay.id],
            }),
          ).then(() => {
            this._notifications.success(`Bay ${bay.code.toUpperCase()} removed`);
            this.bays = this.bays.filter((x) => x.id !== bay.id);
          });
        }
      });
  }

  async onAddProduct(option: FloorBayOption) {
    this._modalService.fromComponent(FloorBayOptionProductDialogComponent, {
      size: 'md',
      closable: true,
      locals: {
        option,
      },
    })
      .subscribe((input) => {
        if (!input) {
          return;
        }
        void lastValueFrom(
          this._dataService.mutate(AddProductsToFloorBayOptionDocument, {
            input: {
              floorBayOptionId: input.floorBayOptionId,
              products: [input],
            },
          }),
        ).then(({ addProductsToFloorBayOption }) => {
          this._notifications.success('Product added');
          this.bays = this.bays.map((x) => {
            return {
              ...x,
              options: x.options.map((o) => {
                if (o.id === addProductsToFloorBayOption.id) {
                  return {
                    ...o,
                    products: addProductsToFloorBayOption.products as FloorBayOptionProduct[],
                  };
                }
                return o;
              }),
            };
          });
        });
      });
  }

  async onAddOption(bay: FloorBay) {
    return this._modalService
      .fromComponent(FloorBayOptionDialogComponent, {
        size: 'sm',
        closable: true,
        locals: {
          bayId: bay.id,
        },
      })
      .subscribe((input) => {
        if (!input) {
          return;
        }
        void lastValueFrom(
          this._dataService.mutate(CreateFloorBayOptionDocument, { input }),
        ).then(({ createFloorBayOption }) => {
          this._notifications.success('Option added');
          this.bays = this.bays.map((b) => {
            return {
              ...b,
              options: b.id === bay.id
                ? [...b.options, createFloorBayOption as FloorBayOption]
                : b.options,
            };
          });
        });
      });
  }

  ngOnInit() {
    this.bays = [...(this.floor.bays ?? [])];
  }
}