import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '@vendure/admin-ui/core';
import { concat, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { graphql } from '../../../gql';
import { Global, GlobalsDocument, GlobalType } from '../../../gql/graphql';

const getGlobalsDocument = graphql(`
  query Globals($filter: GlobalsFilter) {
    globals(filter: $filter) {
      totalItems
      items {
        global {
          type
          code
          productId
          productVariantId
          materialGroup {
            id
            name
          }
        }
      }
    }
  }
`);

@Component({
  selector: 'floor-component-selector',
  templateUrl: './floor-component-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FloorComponentSelectorComponent,
      multi: true,
    },
  ],
})
export class FloorComponentSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output()
  selectedValuesChange = new EventEmitter<Global[]>();
  @Input()
  readonly = false;
  @Input()
  transformControlValueAccessorValue: (value: Global[]) => any[] = value => value;
  @Input()
  multiple = true;
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<Global[]>;
  selectedIds$ = new Subject<string[]>();

  @ViewChild(NgSelectComponent) private ngSelect: NgSelectComponent;

  onChangeFn: (val: any) => void;
  onTouchFn: () => void;
  disabled = false;
  value: Array<string | Global>;
  private subscription: Subscription;

  constructor(
    private readonly _dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initSearchResults();
  }

  private _query(search: string) {
    if (!search) {
      return of([]);
    }
    return this._dataService.query(GlobalsDocument, {
      filter: {
        search,
        globalType: [GlobalType.floor_components],
      },
    })
      .mapSingle(({ globals }) => globals.items.map((x) => x.global as Global));
  }

  private _queryByIds(ids: string[]) {
    if (!ids.length) {
      return of([]);
    }
    // todo filter by ids
    return this._dataService.query(GlobalsDocument, { filter: {} })
      .mapSingle(({ globals }) => globals.items.map((x) => x.global));
  }

  private initSearchResults() {
    const searchItems$ = this.searchInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searchLoading = true)),
      switchMap((search) => this._query(search)),
      tap(() => (this.searchLoading = false)),
    );
    this.subscription = this.selectedIds$
      .pipe(
        switchMap(ids => this._queryByIds(ids)),
      )
      .subscribe(val => {
        this.value = val as Global[];
        this.changeDetectorRef.markForCheck();
      });

    const clear$ = this.selectedValuesChange.pipe(mapTo([]));
    this.searchResults$ = concat(of([]), merge(searchItems$, clear$));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onChange(selected: Global[]) {
    if (this.readonly) {
      return;
    }
    this.selectedValuesChange.emit(selected);
    if (this.onChangeFn) {
      const transformedValue = this.transformControlValueAccessorValue(selected);
      this.onChangeFn(transformedValue);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focus() {
    this.ngSelect.focus();
  }

  writeValue(obj: string | Global[] | Array<string | number> | null): void {
    let valueIds: string[] | undefined;
    if (typeof obj === 'string') {
      try {
        valueIds = JSON.parse(obj) as string[];
      } catch (err) {
        // TODO: log error
        throw err;
      }
    } else if (Array.isArray(obj)) {
      const isIdArray = (input: unknown[]): input is Array<string | number> =>
        input.every(i => typeof i === 'number' || typeof i === 'string');
      if (isIdArray(obj)) {
        valueIds = obj.map(fv => fv.toString());
      } else {
        valueIds = obj.map(fv => fv.productVariantId);
      }
    }
    if (valueIds) {
      // this.value = valueIds;
      this.selectedIds$.next(valueIds);
    }
  }
}
