import { SharedModule } from '@vendure/admin-ui/core';
import { ApplyGroupDialogComponent } from './apply-group-dialog/apply-group-dialog.component';
import { ApplyMaterialDialogComponent } from './apply-material-dialog/apply-material-dialog.component';
import { MaterialGroupSelectorComponent } from './material-group-selector/material-group-selector.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialSelectorComponent } from './material-selector/material-selector.component';

const DECLARATIONS = [
  ApplyGroupDialogComponent,
  MaterialGroupSelectorComponent,
  ApplyMaterialDialogComponent,
  MaterialSelectorComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModule {}