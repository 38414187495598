import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ApplyFacetDialogComponent } from '@vendure/admin-ui/catalog';
import { lastValueFrom, Observable } from 'rxjs';
import {
  AddFacetValueToMaterialGroupAssigmentDocument,
  CreateMaterialGroupAssigmentDocument,
  DeleteMaterialGroupAssigmentDocument,
  FacetValue,
  MaterialGroup,
  MaterialGroupAssigment,
  MaterialGroupAssigmentDocument, RemoveFacetValueToMaterialGroupAssigmentDocument,
  UpdateMaterialGroupAssigmentDocument,
} from '../../gql/graphql';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  NotificationService,
  SharedModule,
  ModalService,
  DataService,
} from '@vendure/admin-ui/core';
import { graphql } from '../../gql';
import { ApplyGroupDialogComponent } from '../common/apply-group-dialog/apply-group-dialog.component';
import { CommonModule } from '../common/common.module';

const getMaterialGroupAssigmentDocument = graphql(`
  query MaterialGroupAssigment {
    materialGroupAssigment {
      id
      parentId
      materialGroup {
        id
        name
      }
      facetValues {
        id
        code
        name
        facet {
          id
          name
          code
        }
      }
    }
  }
`);

const addFacetValueToMaterialGroupAssigmentDocument = graphql(`
  mutation AddFacetValueToMaterialGroupAssigment($input: AddFacetValueToMaterialGroupAssigmentInput!) {
    addFacetValueToMaterialGroupAssigment(input: $input) {
      success
    }
  }
`);

const removeFacetValueToMaterialGroupAssigmentDocument = graphql(`
  mutation RemoveFacetValueToMaterialGroupAssigment($input: RemoveFacetValueFromMaterialGroupAssigmentInput!) {
    removeFacetValueFromMaterialGroupAssigment(input: $input) {
      success
    }
  }
`);

const createMaterialGroupAssigmentDocument = graphql(`
  mutation CreateMaterialGroupAssigment($input: CreateMaterialGroupAssigmentInput!) {
    createMaterialGroupAssigment(input: $input) {
      id
      parentId
      materialGroupId
      facetValues {
        id
        code
        name
      }
    }
  }
`);

const updateMaterialGroupAssigmentDocument = graphql(`
  mutation UpdateMaterialGroupAssigment($input: UpdateMaterialGroupAssigmentInput!) {
    updateMaterialGroupAssigment(input: $input) {
      id
      parentId
      materialGroupId
      facetValues {
        id
        code
        name
      }
    }
  }
`);

const deleteMaterialGroupAssigmentDocument = graphql(`
  mutation DeleteMaterialGroupAssigment($id: [ID!]!) {
    deleteMaterialGroupAssigment(id: $id) {
      result
    }
  }
`);

type Item = MaterialGroupAssigment & {
  level: number;
  path: string;
}

@Component({
  selector: 'material-group-assigment',
  templateUrl: './material-group-assigment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, CommonModule],
})
export class MaterialGroupAssigmentComponent implements OnInit, OnDestroy {
  private _rawItems: MaterialGroupAssigment[] = [];
  items: Item[] = [];

  constructor(
    private readonly _dataService: DataService,
    private readonly _notifications: NotificationService,
    private readonly _modalService: ModalService,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {
  }

  removeFacetValueFromAssigment(item: Item, facet: FacetValue) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove facet value "${facet.code}" from the material group assigment?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          lastValueFrom(
            this._dataService.mutate(RemoveFacetValueToMaterialGroupAssigmentDocument, {
              input: {
                id: item.id,
                facetValueIds: [facet.id],
              },
            }),
          ).then((result) => {
            if (result.removeFacetValueFromMaterialGroupAssigment.success) {
              this._rawItems = this._rawItems.map((x) => {
                if (x.id === item.id) {
                  x.facetValues = x.facetValues.filter((y) => y.id !== facet.id);
                }
                return x;
              });
              this.items = this._mapItems(this._rawItems);
              this._changeDetector.markForCheck();
              this._notifications.success('Material group assigment deleted');
            } else {
              this._notifications.error('Material group assigment delete failed');
            }
          });
        }
      });
  }

  async addFacetValueAndSave(item: Item) {
    this._displayFacetValueModal().subscribe((facetValues) => {
      if (facetValues?.length) {
        void lastValueFrom(
          this._dataService.mutate(AddFacetValueToMaterialGroupAssigmentDocument, {
            input: {
              id: item.id,
              facetValueIds: facetValues.map((x) => x.id),
            },
          }),
        ).then(() => {
          this._notifications.success('Group assigment updated');
          this._rawItems = this._rawItems.map((x) => {
            if (x.id === item.id) {
              x.facetValues = [...x.facetValues, ...facetValues];
            }
            return x;
          });
          this.items = this._mapItems(this._rawItems);
          this._changeDetector.markForCheck();
        });
      }
    });
  }

  async selectGroupAndSave(item: Item) {
    this._displayGroupModal().subscribe((groups) => {
      if (groups?.length) {
        void lastValueFrom(
          this._dataService.mutate(UpdateMaterialGroupAssigmentDocument, {
            input: {
              id: item.id,
              materialGroupId: groups[0].id,
            },
          }),
        ).then(() => {
          this._notifications.success('Group assigment updated');
          this._rawItems = this._rawItems.map((x) => {
            if (x.id === item.id) {
              x.materialGroup = groups[0];
            }
            return x;
          });
          this.items = this._mapItems(this._rawItems);
          this._changeDetector.markForCheck();
        });
      }
    });
  }

  async removeGroupAndSave(item: Item) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove the material group?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          void lastValueFrom(
            this._dataService.mutate(UpdateMaterialGroupAssigmentDocument, {
              input: {
                id: item.id,
                materialGroupId: null,
              },
            }),
          ).then(() => {
            this._notifications.success('Group assigment updated');
            this._rawItems = this._rawItems.map((x) => {
              if (x.id === item.id) {
                x.materialGroup = null;
              }
              return x;
            });
            this.items = this._mapItems(this._rawItems);
            this._changeDetector.markForCheck();
          });
        }
      });
  }

  async remove(item: Item) {
    this._modalService
      .dialog({
        title: 'Confirm action',
        body: `Remove the material group assigment?`,
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .subscribe((result) => {
        if (result) {
          lastValueFrom(
            this._dataService.mutate(DeleteMaterialGroupAssigmentDocument, { id: [item.id] }),
          ).then((result) => {
            if (result.deleteMaterialGroupAssigment.result === 'DELETED') {
              const parents = [item.id];
              this._rawItems = this._rawItems.filter(x => {
                if (x.parentId && parents.includes(x.parentId)) {
                  parents.push(x.id);
                  return false;
                }
                return true;
              });
              this.items = this._mapItems(this._rawItems);
              this._changeDetector.markForCheck();
              this._notifications.success('Material group assigment deleted');
            } else {
              this._notifications.error('Material group assigment delete failed');
            }
          });
        }
      });
  }

  async add(parentId: string | null = null) {
    lastValueFrom(
      this._dataService.mutate(CreateMaterialGroupAssigmentDocument, {
        input: {
          parentId,
        },
      }),
    ).then((result) => {
      const item = result.createMaterialGroupAssigment as MaterialGroupAssigment;
      this._rawItems.push(item);
      this.items = this._mapItems(this._rawItems);
      this._changeDetector.markForCheck();
    });
  }

  ngOnInit() {
    this._dataService
      .query(MaterialGroupAssigmentDocument, {})
      .mapSingle(result => result.materialGroupAssigment as MaterialGroupAssigment[])
      .subscribe((items) => {
        this._rawItems = [...items.map((x) => ({ ...x }))];
        this.items = this._mapItems(this._rawItems);
        this._changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
  }

  private _displayGroupModal(): Observable<MaterialGroup[] | undefined> {
    return this._modalService.fromComponent(ApplyGroupDialogComponent, {
      size: 'md',
      closable: true,
      locals: {
        multiple: false,
      },
    });
  }

  private _displayFacetValueModal(): Observable<FacetValue[] | undefined> {
    return this._modalService.fromComponent(ApplyFacetDialogComponent, {
      size: 'md',
      closable: true,
    });
  }

  private _mapItems(
    items: MaterialGroupAssigment[],
    parentId: string | null = null,
    level = 0,
    parentPath: string[] = [],
  ): Item[] {
    if (level > 50) {
      return [];
    }
    const result: Item[] = [];
    items.filter(item => item.parentId === parentId).forEach(item => {
      const path = [...parentPath, item.id];
      result.push({
        ...item,
        level,
        path: path.join('.'),
      });
      const children = this._mapItems(items, item.id, level + 1, path);
      result.push(...children);
    });
    const collator = new Intl.Collator([], { numeric: true });
    return result.sort((a, b) => collator.compare(a.path, b.path));
  }
}