import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import {
  Dialog,
} from '@vendure/admin-ui/core';
import { Material } from '../../../gql/graphql';
import { MaterialSelectorComponent } from '../material-selector/material-selector.component';

@Component({
  selector: 'apply-material-dialog',
  templateUrl: './apply-material-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyMaterialDialogComponent implements Dialog<Material[]>, AfterViewInit {
  @ViewChild(MaterialSelectorComponent)
  private selector: MaterialSelectorComponent;
  resolveWith: (result?: Material[]) => void;
  selectedValues: Material[] = [];
  // Provided by caller
  materials: Material[]; // todo?

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.selector.focus(), 0);
  }

  selectValues() {
    this.resolveWith(this.selectedValues);
  }

  cancel() {
    this.resolveWith();
  }
}
