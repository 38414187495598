/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query VanBase($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        code\n        name\n        types\n        lengths\n        options {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.VanBaseDocument,
    "\n  query GetVanBaseDetail($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        name\n        size\n        code\n        types\n        lengths\n      }\n    }\n  }\n": types.GetVanBaseDetailDocument,
    "\n  query VanBases($options: VanBaseListOptions) {\n    vanBases(options: $options) {\n      items {\n        id\n        name\n        vanCode\n        vanType\n        bodyLength\n      }\n      totalItems\n    }\n  }\n": types.VanBasesDocument,
    "\n  query GetVanDetail($id: ID!) {\n    van(id: $id) {\n      id\n      code\n      lengths\n      name\n      yearFrom\n      yearTo\n      size\n      types\n      bases {\n        id\n        vanCode\n        vanType\n        bodyLength\n      }\n      options {\n        id\n        name\n        category\n        facet {\n          id\n          code\n          name\n        }\n        values {\n          id\n          code\n          name\n          facet {\n            id\n            code\n            name\n          }\n        }\n      }\n    }\n  }\n": types.GetVanDetailDocument,
    "\n  mutation AddVanOptionFacetValues($input: AddVanOptionFacetValuesInput!) {\n    addVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n": types.AddVanOptionFacetValuesDocument,
    "\n  mutation RemoveVanOptionFacetValues($input: RemoveVanOptionFacetValuesInput!) {\n    removeVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n": types.RemoveVanOptionFacetValuesDocument,
    "\n  mutation UpdateVanOption($input: UpdateVanOptionInput!) {\n    updateVanOption(input: $input) {\n      success\n    }\n  }\n": types.UpdateVanOptionDocument,
    "\n  query Vans($options: VanListOptions) {\n    vans(options: $options) {\n      items {\n        id\n        code\n        lengths\n        name\n        size\n        types\n        bases {\n          id\n          vanCode\n          vanType\n          bodyLength\n        }\n      }\n      totalItems\n    }\n  }\n": types.VansDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query VanBase($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        code\n        name\n        types\n        lengths\n        options {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query VanBase($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        code\n        name\n        types\n        lengths\n        options {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetVanBaseDetail($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        name\n        size\n        code\n        types\n        lengths\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetVanBaseDetail($id: ID!) {\n    vanBase(id: $id) {\n      id\n      vanCode\n      vanType\n      bodyLength\n      vans {\n        id\n        name\n        size\n        code\n        types\n        lengths\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query VanBases($options: VanBaseListOptions) {\n    vanBases(options: $options) {\n      items {\n        id\n        name\n        vanCode\n        vanType\n        bodyLength\n      }\n      totalItems\n    }\n  }\n"): (typeof documents)["\n  query VanBases($options: VanBaseListOptions) {\n    vanBases(options: $options) {\n      items {\n        id\n        name\n        vanCode\n        vanType\n        bodyLength\n      }\n      totalItems\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetVanDetail($id: ID!) {\n    van(id: $id) {\n      id\n      code\n      lengths\n      name\n      yearFrom\n      yearTo\n      size\n      types\n      bases {\n        id\n        vanCode\n        vanType\n        bodyLength\n      }\n      options {\n        id\n        name\n        category\n        facet {\n          id\n          code\n          name\n        }\n        values {\n          id\n          code\n          name\n          facet {\n            id\n            code\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetVanDetail($id: ID!) {\n    van(id: $id) {\n      id\n      code\n      lengths\n      name\n      yearFrom\n      yearTo\n      size\n      types\n      bases {\n        id\n        vanCode\n        vanType\n        bodyLength\n      }\n      options {\n        id\n        name\n        category\n        facet {\n          id\n          code\n          name\n        }\n        values {\n          id\n          code\n          name\n          facet {\n            id\n            code\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddVanOptionFacetValues($input: AddVanOptionFacetValuesInput!) {\n    addVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation AddVanOptionFacetValues($input: AddVanOptionFacetValuesInput!) {\n    addVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveVanOptionFacetValues($input: RemoveVanOptionFacetValuesInput!) {\n    removeVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveVanOptionFacetValues($input: RemoveVanOptionFacetValuesInput!) {\n    removeVanOptionFacetValues(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateVanOption($input: UpdateVanOptionInput!) {\n    updateVanOption(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateVanOption($input: UpdateVanOptionInput!) {\n    updateVanOption(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Vans($options: VanListOptions) {\n    vans(options: $options) {\n      items {\n        id\n        code\n        lengths\n        name\n        size\n        types\n        bases {\n          id\n          vanCode\n          vanType\n          bodyLength\n        }\n      }\n      totalItems\n    }\n  }\n"): (typeof documents)["\n  query Vans($options: VanListOptions) {\n    vans(options: $options) {\n      items {\n        id\n        code\n        lengths\n        name\n        size\n        types\n        bases {\n          id\n          vanCode\n          vanType\n          bodyLength\n        }\n      }\n      totalItems\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;