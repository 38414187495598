<vdr-page-block>
  <vdr-action-bar>
    <vdr-ab-left>
    </vdr-ab-left>
    <vdr-ab-right>
      <a class="btn btn-primary" [routerLink]="['./', 'create']">
        <clr-icon shape="plus"></clr-icon>
        Create a material
      </a>
    </vdr-ab-right>
  </vdr-action-bar>
</vdr-page-block>

<vdr-data-table-2
  id="material-list"
  [items]="items$ | async"
  [itemsPerPage]="itemsPerPage$ | async"
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async"
  [filters]="filters"
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  <vdr-bulk-action-menu
    locationId="material-list"
    [hostComponent]="this"
    [selectionManager]="selectionManager"
  ></vdr-bulk-action-menu>
  <vdr-dt2-search
    [searchTermControl]="searchTermControl"
    searchTermPlaceholder="Filter"
  />

  <vdr-dt2-column id="featuredAsset" [heading]="'Preview'">
    <ng-template let-material="item">
      <div class="image-placeholder">
        <img
          *ngIf="material.featuredAsset as asset; else imagePlaceholder"
          style="width: 50px; height: 50px;"
          [src]="asset | assetPreview : 'tiny'"
        />
        <ng-template #imagePlaceholder>
          <div class="placeholder">
            <clr-icon shape="image" size="48"/>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </vdr-dt2-column>

  <vdr-dt2-column id="id" [heading]="'Code'">
    <ng-template let-material="item">
      <a [routerLink]="['./', material.id]">
        <vdr-chip [colorFrom]="material.code">
          <span>{{ material.code }}</span>
        </vdr-chip>
      </a>
    </ng-template>
  </vdr-dt2-column>

  <vdr-dt2-column id="name" [heading]="'Name'">
    <ng-template let-material="item">
      {{ material.name }}
    </ng-template>
  </vdr-dt2-column>

  <vdr-dt2-column id="glb" [heading]="'GLB file'">
    <ng-template let-material="item">
      <a [href]="material.asset.source">
        {{ material.asset.name }}
      </a>
    </ng-template>
  </vdr-dt2-column>

  <vdr-dt2-column
    id="created-at"
    [hiddenByDefault]="true"
    [heading]="'common.created-at' | translate"
    [sort]="sorts.get('createdAt')"
  >
    <ng-template let-material="item">
      {{ material.createdAt | localeDate : 'short' }}
    </ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    id="updated-at"
    [hiddenByDefault]="true"
    [heading]="'common.updated-at' | translate"
    [sort]="sorts.get('updatedAt')"
  >
    <ng-template let-material="item">
      {{ material.updatedAt | localeDate : 'short' }}
    </ng-template>
  </vdr-dt2-column>
</vdr-data-table-2>