export const extensionRoutes = [  {
    path: 'extensions/van',
    loadChildren: () => import('./extensions/van-base-ui/routes'),
  },
  {
    path: 'extensions/configs',
    loadChildren: () => import('./extensions/config-ui/routes'),
  },
  {
    path: 'extensions/material',
    loadChildren: () => import('./extensions/material-ui/routes'),
  }];
