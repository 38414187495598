<ng-template vdrDialogTitle>
  {{ bay ? 'Edit the bay':'Create a bay' }}
</ng-template>


<form
  class="form"
  [formGroup]="form"
>
  <vdr-form-field
    label="Name"
    for="name"
  >
    <select
      #code
      formControlName="code"
    >
      <option
        *ngFor="let code of bayCodes"
        [value]="code"
      >
        Bay {{ code.toUpperCase() }}
      </option>
    </select>
  </vdr-form-field>
</form>

<ng-template vdrDialogButtons>
  <button
    type="button"
    class="btn"
    (click)="cancel()"
  >
    {{ 'common.cancel' | translate }}
  </button>
  <button
    type="submit"
    (click)="submit()"
    [disabled]="form.pristine || form.invalid"
    class="btn btn-primary"
  >
    {{ bay ? 'Update':'Create' }}
  </button>
</ng-template>
