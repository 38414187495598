import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';
import {
  Floor,
} from '../../../gql/graphql';
import { CommonModule } from '../../common/common.module';
import { BaysComponent } from './bays.component';
import { CombinationsComponent } from './combinations.component';

@Component({
  selector: 'floor-attributes',
  templateUrl: './floor-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    BaysComponent,
    CombinationsComponent,
  ],
})
export class FloorAttributesComponent {
  @Input()
  floor: Floor;
}