import { SharedModule } from '@vendure/admin-ui/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { VanBaseSelectorComponent } from './van-base-selector/van-base-selector.component';

const DECLARATIONS = [
  VanBaseSelectorComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModule {}